.coverAndProfile__coverPhotoWrapper {
    width: 100%;
    max-height: 370px;
}

.coverAndProfile__coverPhoto {
    width: 100%;
    height: 370px;
}

.coverAndProfile__belowCoverPhoto {
    margin: 0 10%;
    display: flex;
    justify-content: space-between;
}

.coverAndProfile__blankProfilePhotoWrapper {
    width: 50px;
}

.coverAndProfile__profilePhotoWrapper {
    position: absolute;
    transform: translateY(calc(-60% - 10px));
    border: 10px solid #fff;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}

.coverAndProfile__profilePhoto{
    width: 225px;
    height: 300px;
    padding: 0;
    max-width: 225px;
    max-height: 300px;
}


.coverAndProfile__profileMenu {
    display: flex;
    width: 300px;
    justify-content: space-between;
    align-items: center;
    color: #333;
}

