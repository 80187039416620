.MyProfileContent {
    display: flex;
    margin: 0 10%;
}

/*---------------- Divided in to three columns ------------*/
.MyProfileContent__userDescription {
    width: 30%;
    padding: 15px;
}

.MyProfileContent__userDescriptionTopEmptySpace {
    height: 30px;
}