.coverAndProfile__coverPhotoWrapper {
    width: 100%;
    max-height: 370px;
}

.coverAndProfile__coverPhoto {
    width: 100%;
    max-height: 370px;
}

.coverAndProfile__belowCoverPhoto {
    margin: 0 10%;
    display: flex;
    justify-content: space-between;
}

.coverAndProfile__blankProfilePhotoWrapper {
    width: 50px;
}


.coverAndProfile__blankProfilePhotoWrapper2 {
    width: 350px;
}



.coverAndProfile__profilePhotoWrapper {
    max-width: 245px;
    max-height: 320px;
    position: absolute;
    transform: translateY(calc(-60% - 10px));
    border: 10px solid #fff;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;
}

.coverAndProfile__profilePhoto{
    padding: 0;
    max-width: 225px;
    max-height: 300px;
}


.coverAndProfile__profileMenu {
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;
    color: #333;

}

.coverAndProfile__socialmenu {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    color: #333;
    margin-top: 20px;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;


}
.coverAndProfile__About{
    align-content: flex-start;
    margin-right: 125px;
}

.coverAndProfile__SocialIcon{
    display: flex;
    align-items: center;
}

.active {
    color: #D21A22;
}

