.homeContent {
    margin: 0.5% 5%;
    display: flex;
}

/*---------------- Divided in to three columns ------------*/

.homeContent_profilecard{
    margin-top: 190px;
}

.homeContent__nets {
    width: 75%;
    padding: 15px;
   
}
.homecontent__extreme_right{
    

}
.homeContent__recent {
    padding: 15px;
    
}

.homecontent__Advertisement{
    padding: 15px;

}