.JobNetCard_ProgressBar {

    width: 100%;
    margin-top: 20px;
  }
  
  .JobNetCard__ribbon {
    position: absolute;
    transform: translateY(15px);
    padding: 2px 15px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.0em;
    width: 120px;
    color: white;
    border-radius: 0 0.156em 0.156em 0;
    background: #D21A22;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
  }
  
  .JobNetCard__ribbon:before, .JobNetCard__ribbon:after {
    position: absolute;
    content: '';
    display: block;
  }
  
  .JobNetCard__ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
  }
  
  .JobNetCard__ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 600px) {
    .JobNetCard__ribbon {
        line-height: 1.143em;
        padding: 0.5em;
    }
    .JobNetCard__ribbon:before, .JobNetCard__ribbon:after {
        font-size: 0.714em;
    }
  }