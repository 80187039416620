.userDescriptionCard{
    padding: 30px !important;    
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.userDescriptionCard__divider{
    height: 1px;
    background-color: #d21a22;
    width: 30%;
    margin: 10px 0 10px 0;
}

.userDescriptionCard__name{
    font-size: 24px;
}

.userDescriptionCard__username{
    font-weight: 500;
}

.userDescriptionCard__body{
    margin-top: 20px;
}

.userDescriptionCard__about{
    margin-bottom: 20px;
}


.userDescriptionCard__introListItem{
    margin: 10px 0;
   display: flex ;
   justify-content: flex-start;
   align-items: center;
}

.userDescriptionCard__introListItem span{
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
}