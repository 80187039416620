.createNetDialog__titleBox {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
}

.createNetDialog__title {
    display: flex !important;
    justify-content: flex-start;
    align-items: center !important;
    font-size: 26px;
    font-weight: 600;
}

.createNetDialog__magnetIcon {
    margin-left: 10px;
}

.createNetDialog__textFields {
    width: 100% !important;
    margin-bottom: 10px !important;
}

.createNetDialog__mediaTypeForm{
    margin-top: 10px !important;
}

.createNetDialog__descriptionField {
    outline: none !important;
    border: none !important;
}

.createNetDialog__tagsContainer {
    margin-top: 10px !important;
}

.createNetDialog__addTagContainer {
    margin-top: 10px !important;
}

.createNetDialog__addTagInput {
    width: 100%;
}

.createNetDialog__addTagButton {
    margin-left: 10px !important;
    width: 30%;
}

.createNetDialog__showTagsContainer {
    padding: 10px !important;
    display: flex;
}

.createNetDialog__tagChip {
    border-radius: 12px !important;
    margin: 0 2px !important;
    background: rgb(18, 37, 86) !important;
    color: white !important;
    text-transform: uppercase;
}

.createNetDialog__previewButton{
    background-color: rgb(240, 240, 240) !important;
    margin-right: 10px !important;
}

.createNetDialog__publishButton{
    margin-right: 16px !important;
}