.login_left {
    height: 100vh;
    overflow-y: auto;
    width: 100%;
    padding: 10px 20px;
    background-color: #eee;
}

.login_left::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #eee;
    background-color: rgba(0, 0, 0, .5);
}

.login_right {
    background-color: aqua;
    height: 100vh;
    width: 100%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toolbar {
    width: 100%;
    height: 25%;
    /* background-color: blue; */
    display: flex;
    border-bottom: 1px solid #aaa;
}

.toolbar_image_container {
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7%;
}

.logo {
    width: 100%;
    border-bottom: 1px solid #eee;
}

.toolbar_text {
    /* background-color: blueviolet; */
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.toolbar_text_change_content::after {
    content: '';
    animation: changetext 3s infinite linear;
    font-size: 2rem;
    color: #d21a22;
    margin-left: 8px;
    font-weight: bold;
}

@keyframes changetext {
    0% {
        content: "Cool!";
    }
    50% {
        content: "Awesome!";
    }
    100% {
        content: "Perfect!";
    }
}

@media (max-width:500px) {
    .toolbar_text {
        margin-left: 20px;
    }
    .login_left {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1 !important;
    }
}

@media (min-width:500px) {
    .button1 {
        display: none !important;
    }
}

.auth_input {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.socialMediaIcon {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px !important;
}

.google_icon_wrapper {
    width: calc(50% - 5px);
    display: flex;
    height: 45px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 2px;
    font-weight: 500;
}

.google_icon_wrapper:hover {
    opacity: 0.9;
    cursor: pointer;
    background-color: #E5E5E5;
}

.google_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.icon_text {
    padding: 10px 10px 10px 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.facebook_icon_wrapper {
    width: calc(50% - 5px);
    display: flex;
    height: 45px;
    background-color: #4c69ba;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 2px;
    font-weight: 500;
}

.facebook_icon_wrapper:hover {
    opacity: 0.9;
    cursor: pointer;
    background-color: #5E78C1;
}

.facebook_icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.form_card {
    min-height: 320px;
    width: 70%;
    /* box-shadow: 0px 0px 5px 1px rgb(190, 190, 190) !important; */
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.InfoIcon {
    position: absolute;
    color: rgb(105, 102, 102);
    display: flex;
    bottom: 0 !important;
    right: 0 !important;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
}

.form_card_header {
    background-color: #d21a22;
    text-align: center;
    color: #fff;
}

.form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.codeAndUserIdWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.form_codeField {
    flex: 0.2;
}

.form_textField {
    margin-top: 30px;
    flex: 0.8;
    margin-left: 5px !important;
}

.termsAndConditions {
    margin-top: 30px !important;
}

.termsAndConditionsText {
    margin-top: 40px;
    font-weight: 600;
}

.termsOfUse {
    color: #d21a22;
    cursor: pointer;
}

.form_button {
    margin-top: 10px !important;
    width: 80%;
    height: 50px;
    font-size: 16px;
}

.otpForm {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.otpForm_heading_wrapper {
    width: 80%;
    margin-bottom: 20px;
}

.otpForm_heading {
    text-align: start;
}

.otpForm_textField {
    margin-top: 30px;
    width: 80%;
}

.otpForm_resendAndBackButton {
    margin-top: 5px;
    width: 85%;
    display: flex;
    justify-content: space-between;
}

.otpForm_submitButton {
    margin-top: 30px !important;
    width: 80%;
    height: 50px;
    font-size: 16px;
}

.countDownTimer {
    margin-top: 5px !important;
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin: 0 15px 15px 0;
}

.countDownTimer span {
    margin: 0 5px 0 5px;
}

.createUsername__form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.createUsername__textField {
    width: 80%;
}

.createUsername__submitButton {
    margin-top: 30px !important;
    width: 80%;
    height: 50px;
    font-size: 16px;
}

.createUsername__profileTypeButtonsGrid {
    margin-top: 30px !important;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.createUsername__headingWrapper {
    width: 100%;
    margin-bottom: 15px;
}

.createUsername__headingWrapper p {
    font-weight: 600;
}

.createUsername__profileTypeButtonsGrid p {
    text-align: start;
}

.createUsername__upperGrid {
    margin-bottom: 7px !important;
}

/*Student profile button starts*/

.createUsername__studentprofileButton {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.createUsername__studentprofileButton:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #D21A22;
    color: black;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
}

.pressedButtonStateStudent {
    background-color: #D21A22;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
}

.pressedButtonStateStudent:hover {
    color: #ccc;
}

/*Student profile button ends*/

/*Startup profile button starts*/

.createUsername__startupprofileButton {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.createUsername__startupprofileButton:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #FDD539;
    color: black;
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.pressedButtonStatestartup {
    background-color: #FDD539;
    color: #444;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Startup profile button ends*/

/*corporate profile button starts*/

.createUsername__corporateprofileButton {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.createUsername__corporateprofileButton:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #EFBC1A;
    color: black;
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.pressedButtonStatecorporate {
    background-color: #EFBC1A;
    color: #444;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*corporate profile button ends*/

/*mentor profile button starts*/

.createUsername__mentorprofileButton {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.createUsername__mentorprofileButton:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #192A57;
    color: black;
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.pressedButtonStatementor {
    background-color: #192A57;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pressedButtonStatementor:hover {
    color: #ddd
}

/*mentor profile button ends*/