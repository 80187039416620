
  .UserProfile_profile-card {
    width: 100%;
    text-align: center;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 8px;
    overflow: hidden;
  }

  .UserProfile_card-header {
    background: url("https://source.unsplash.com/500x500/?science,technology") no-repeat center;
    padding: 60px 40px;
  }

  .UserProfile_pic {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 50%;
  }

  .UserProfile_profileimage {
    position: absolute;
    background: linear-gradient(130deg, #D21A22, #192A57, #EFBC1A);
    padding: 2px;
    background-size: 200% 200%;
    animation: animated-gradient 5s linear infinite;
    transform: translateY(-50%);
  }

  @keyframes animated-gradient {
    25% {
      background-position: left bottom;
    }
    50% {
      background-position: right bottom;
    }
    75% {
      background-position: right top;
    }
    100% {
      background-position: left top;
    }
  }

  .UserProfile_pic img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .UserProfile_fbicon {
    margin: 10px;
  }

  .UserProfile_twittericon {
    margin: 10px;
  }

  .UserProfile_githubicon {
    margin: 10px;
  }

  .UserProfile_linkedinicon {
    margin: 10px;
  }

  .UserProfile_name {
    color: black;
    font-size: 28px;
    font-weight: 600;
    margin-top: 80px;
  }

  .UserProfile_desc {
    font-size: 18px;
    color: black;
  }

  .UserProfile_sm {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .UserProfile_sm a {
    color: black;
    width: 56px;
    font-size: 22px;
    transition: .3s linear;
  }

  .UserProfile_sm a:hover {
    color: black;
  }

  .UserProfile_contact-btn {
    display: inline-block;
    padding: 12px 50px;
    color: black;
    border: 2px solid black;
    border-radius: 6px;
    margin-top: 16px;
    transition: .3s linear;
    text-decoration: none;
  }

  .UserProfile_contact-btn:hover {
    background: black;
    color: #f2f2f2;
  }

  /* .loginNetCard {
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 0px !important;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px !important;
  }

  .loginNetCard__tags {
    width: 95%;
  }

  .loginNetCard__tags span {
    padding: 1px 12px;
    border-radius: 12px;
    margin: 0 2px;
    background: rgb(18, 37, 86);
    color: white;
    text-transform: uppercase;
  }

  .loginNetCard__heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 5px 0px 14px 0;
  }

  .loginNetCard__subheading {
    padding-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  .loginNetCard__video {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
  }

  .loginNetCard__lcsIcons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .loginNetCard__lcsIcons span {
    margin-left: 5px;
  }

  .loginNetCard__csIcons {
    display: flex;
    justify-content: space-between;
  }

  .loginNetCard__likeIcon {
    display: flex;
    align-items: flex-start;
  }

  .loginNetCard__commentIcon {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
  }

  .loginNetCard__shareIcon {
    display: flex;
    align-items: flex-start;
  }

  .Netcard_header {
    display: flex;
  }

  .NetCard_Hamburger {
    display: flex !important;
    align-items: flex-end !important;
  }
   */