.NetCard {
    /* box-shadow: 0px 0px 8px 1px #D21A22 !important; */
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px !important;
    transition: all 5s !important;
}

.Netcard_header {
    display: flex;
    padding: 0 8px 5px 22px;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.NetCard_Hamburger {
    display: flex !important;
    align-items: flex-end !important;
    z-index: 10 !important;
    outline: none !important;
    border: none !important;
}

.NetCard_hamburgerMenu {
    z-index: inherit !important;
    outline: inherit !important;
    border: inherit !important;
}

.NetCard__tags {
    margin-top: 53px !important;
}


.NetCard__singleTag {
    margin-right: 5px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-color: #aaa !important;
    background-color: #eee !important;
    color: black !important;
}

.NetCard__post{
    padding: 0 25px 25px 25px;
}

.NetCard__accordion {
    padding: 0px !important;
    box-shadow: none !important;
    z-index: 0 !important;
    margin: 0 !important;
}

.NetCard__accordionSummary{
    margin: 0 !important;
    padding: 0 !important;
}

.NetCard__accordionHeading{
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

.NetCard__accordionSubheading{
    font-family: 'Montserrat', sans-serif;
}


.NetCard__headingAndSubheading {
    z-index: 0 !important;
}


.NetCard__heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 5px 0px 10px 0 !important;
}

.NetCard__subheading {
    padding-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
}

.NetCard__video {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
}

.NetCard__image_wrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}

.NetCard__image {
    width: 100%;
    height: 300px;
    border-radius: 10px;    
}

.NetCard__lcsIcons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.NetCard__lcsIcons span {
    margin-left: 5px;
}

.NetCard__csIcons {
    display: flex;
    justify-content: space-between;
}

.NetCard__likeIcon {
    display: flex;
    align-items: flex-start;
}

.NetCard__commentIcon {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
}

.NetCard__shareIcon {
    display: flex;
    align-items: flex-start;
}

.NetCard__ribbon {
    position: absolute;
    transform: translateY(15px);
    padding: 3px 15px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.0em;
    width: 120px;
    color: white;
    border-radius: 0 0.156em 0.156em 0;
    background: #D21A22;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
}

.NetCard__ribbon:before, .NetCard__ribbon:after {
    position: absolute;
    content: '';
    display: block;
}

.NetCard__ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
}

.NetCard__ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}
