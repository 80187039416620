@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Raleway', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 11px;
}

::-webkit-scrollbar:horizontal {
  height: 11px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, .5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.Login_login_left__3EQnx {
    height: 100vh;
    overflow-y: auto;
    width: 100%;
    padding: 10px 20px;
    background-color: #eee;
}

.Login_login_left__3EQnx::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #eee;
    background-color: rgba(0, 0, 0, .5);
}

.Login_login_right__1pojv {
    background-color: aqua;
    height: 100vh;
    width: 100%;
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login_toolbar__cO4EG {
    width: 100%;
    height: 25%;
    /* background-color: blue; */
    display: flex;
    border-bottom: 1px solid #aaa;
}

.Login_toolbar_image_container__3CSAR {
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7%;
}

.Login_logo__326q7 {
    width: 100%;
    border-bottom: 1px solid #eee;
}

.Login_toolbar_text__2kwdM {
    /* background-color: blueviolet; */
    color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Login_toolbar_text_change_content__32Sfo::after {
    content: '';
    -webkit-animation: Login_changetext__Fa7Tm 3s infinite linear;
            animation: Login_changetext__Fa7Tm 3s infinite linear;
    font-size: 2rem;
    color: #d21a22;
    margin-left: 8px;
    font-weight: bold;
}

@-webkit-keyframes Login_changetext__Fa7Tm {
    0% {
        content: "Cool!";
    }
    50% {
        content: "Awesome!";
    }
    100% {
        content: "Perfect!";
    }
}

@keyframes Login_changetext__Fa7Tm {
    0% {
        content: "Cool!";
    }
    50% {
        content: "Awesome!";
    }
    100% {
        content: "Perfect!";
    }
}

@media (max-width:500px) {
    .Login_toolbar_text__2kwdM {
        margin-left: 20px;
    }
    .Login_login_left__3EQnx {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1 !important;
    }
}

@media (min-width:500px) {
    .Login_button1__3prcD {
        display: none !important;
    }
}

.Login_auth_input__360oO {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login_socialMediaIcon__3Aw-f {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px !important;
}

.Login_google_icon_wrapper__nMwXP {
    width: calc(50% - 5px);
    display: flex;
    height: 45px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 2px;
    font-weight: 500;
}

.Login_google_icon_wrapper__nMwXP:hover {
    opacity: 0.9;
    cursor: pointer;
    background-color: #E5E5E5;
}

.Login_google_icon_box__3c382 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.Login_icon_text__z0MQy {
    padding: 10px 10px 10px 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.Login_facebook_icon_wrapper__33iYu {
    width: calc(50% - 5px);
    display: flex;
    height: 45px;
    background-color: #4c69ba;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    border-radius: 2px;
    font-weight: 500;
}

.Login_facebook_icon_wrapper__33iYu:hover {
    opacity: 0.9;
    cursor: pointer;
    background-color: #5E78C1;
}

.Login_facebook_icon_box__3GM4F {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.Login_form_card__3lTjB {
    min-height: 320px;
    width: 70%;
    /* box-shadow: 0px 0px 5px 1px rgb(190, 190, 190) !important; */
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.Login_InfoIcon__H774r {
    position: absolute;
    color: rgb(105, 102, 102);
    display: flex;
    bottom: 0 !important;
    right: 0 !important;
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 10px;
}

.Login_form_card_header__34sO1 {
    background-color: #d21a22;
    text-align: center;
    color: #fff;
}

.Login_form__3Jk65 {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login_codeAndUserIdWrapper__3d5UD {
    display: flex;
    width: 100%;
    justify-content: center;
}

.Login_form_codeField__HRKu3 {
    flex: 0.2 1;
}

.Login_form_textField__1FrOY {
    margin-top: 30px;
    flex: 0.8 1;
    margin-left: 5px !important;
}

.Login_termsAndConditions__3qndI {
    margin-top: 30px !important;
}

.Login_termsAndConditionsText__zmkzz {
    margin-top: 40px;
    font-weight: 600;
}

.Login_termsOfUse__3bJT6 {
    color: #d21a22;
    cursor: pointer;
}

.Login_form_button__1K6zX {
    margin-top: 10px !important;
    width: 80%;
    height: 50px;
    font-size: 16px;
}

.Login_otpForm__14kfn {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login_otpForm_heading_wrapper__26xr1 {
    width: 80%;
    margin-bottom: 20px;
}

.Login_otpForm_heading__3X-QK {
    text-align: start;
}

.Login_otpForm_textField__23KVE {
    margin-top: 30px;
    width: 80%;
}

.Login_otpForm_resendAndBackButton__kLdLJ {
    margin-top: 5px;
    width: 85%;
    display: flex;
    justify-content: space-between;
}

.Login_otpForm_submitButton__1Dpnz {
    margin-top: 30px !important;
    width: 80%;
    height: 50px;
    font-size: 16px;
}

.Login_countDownTimer__2d0fS {
    margin-top: 5px !important;
    width: 80%;
    display: flex;
    justify-content: flex-end;
    margin: 0 15px 15px 0;
}

.Login_countDownTimer__2d0fS span {
    margin: 0 5px 0 5px;
}

.Login_createUsername__form__3J6hn {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Login_createUsername__textField__3XquH {
    width: 80%;
}

.Login_createUsername__submitButton__3XAVF {
    margin-top: 30px !important;
    width: 80%;
    height: 50px;
    font-size: 16px;
}

.Login_createUsername__profileTypeButtonsGrid__32LWk {
    margin-top: 30px !important;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login_createUsername__headingWrapper__3y0Ja {
    width: 100%;
    margin-bottom: 15px;
}

.Login_createUsername__headingWrapper__3y0Ja p {
    font-weight: 600;
}

.Login_createUsername__profileTypeButtonsGrid__32LWk p {
    text-align: start;
}

.Login_createUsername__upperGrid__37XjB {
    margin-bottom: 7px !important;
}

/*Student profile button starts*/

.Login_createUsername__studentprofileButton__1h7OE {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_createUsername__studentprofileButton__1h7OE:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #D21A22;
    color: black;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_pressedButtonStateStudent__2V4LY {
    background-color: #D21A22;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
}

.Login_pressedButtonStateStudent__2V4LY:hover {
    color: #ccc;
}

/*Student profile button ends*/

/*Startup profile button starts*/

.Login_createUsername__startupprofileButton__g5hsa {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_createUsername__startupprofileButton__g5hsa:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #FDD539;
    color: black;
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_pressedButtonStatestartup__2qhB2 {
    background-color: #FDD539;
    color: #444;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Startup profile button ends*/

/*corporate profile button starts*/

.Login_createUsername__corporateprofileButton__3dsMn {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_createUsername__corporateprofileButton__3dsMn:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #EFBC1A;
    color: black;
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_pressedButtonStatecorporate__3A6U1 {
    background-color: #EFBC1A;
    color: #444;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*corporate profile button ends*/

/*mentor profile button starts*/

.Login_createUsername__mentorprofileButton__2a_MN {
    width: 100%;
    height: 50px;
    border: 2px solid rgb(146, 146, 146);
    color: rgb(146, 146, 146);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_createUsername__mentorprofileButton__2a_MN:hover {
    width: 100%;
    height: 50px;
    border: 2px solid #192A57;
    color: black;
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.Login_pressedButtonStatementor__3pGNI {
    background-color: #192A57;
    color: #fff;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Login_pressedButtonStatementor__3pGNI:hover {
    color: #ddd
}

/*mentor profile button ends*/
.loginNetCard{
    /* box-shadow: 0px 0px 8px 1px #D21A22 !important; */
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    /* box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2) !important; */
    border-radius: 4px !important; 
    width: 100%;
    padding: 0 !important;
    margin-bottom: 10px !important;
}


.loginNetCard__profile_wrapper{
    display: flex;
    justify-content: flex-start; 
    padding: 20px;
}

.loginNetCard__profilePhoto{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 8px 10px -5px #000;
}

.loginNetCard__nameAndDesignation{
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   
}

.loginNetCard__name{
    font-size: 30px;
    font-weight: 500;
}

.loginNetCard__designation{
    font-size: 20px;
    font-weight: 400;
}

.loginNetCard__netWorth{
    font-size: 12px;
}

.loginNetCard__divider{
    height: 1px;
    width: 100%;
    /* margin: 15px 0; */
    background-color: #ddd;
}


.loginNetCard__tags {
    margin-top: 55px !important;
}


.loginNetCard__singleTag {
    margin-right: 5px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-color: #aaa !important;
    background-color: #eee !important;
    color: black !important;
}

.loginNetCard__post{
    padding: 0 25px 25px 25px;
}

.loginNetCard__accordion {
    padding: 0px !important;
    box-shadow: none !important;
    z-index: 0 !important;
    margin: 0 !important;
}

.loginNetCard__accordionSummary{
    margin: 0 !important;
    padding: 0 !important;
}

.loginNetCard__accordionHeading{
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

.loginNetCard__accordionSubheading{
    font-family: 'Montserrat', sans-serif;
}


.loginNetCard__headingAndSubheading {
    z-index: 0 !important;
}


.loginNetCard__heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 10px 0px !important;
}

.loginNetCard__subheading {
    padding-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
}

.loginNetCard__video {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
}

.loginNetCard__image_wrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}

.loginNetCard__image {
    width: 100%;
    height: 300px;
    border-radius: 10px;    
}

.loginNetCard__lcsIcons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.loginNetCard__lcsIcons span {
    margin-left: 5px;
}

.loginNetCard__csIcons {
    display: flex;
    justify-content: space-between;
}

.loginNetCard__likeIcon {
    display: flex;
    align-items: flex-start;
}

.loginNetCard__commentIcon {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
}

.loginNetCard__shareIcon {
    display: flex;
    align-items: flex-start;
}

.loginNetCard__ribbon {
    position: relative;
    transform: translateY(180px);
    padding: 3px 15px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.0em;
    width: 120px;
    color: #fff;
    border-radius: 0 0.156em 0.156em 0;
    background: #d21a22;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
}

.loginNetCard__ribbon:before, .loginNetCard__ribbon:after {
    position: absolute;
    content: '';
    display: block;
}

.loginNetCard__ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
}

.loginNetCard__ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}

.customizeToolbar{
    Height: 50px !important;
}
.makeStyles-inputInput-7 {
    width: 500px!important;
}
.homeContent {
    margin: 0.5% 5%;
    display: flex;
}

/*---------------- Divided in to three columns ------------*/

.homeContent_profilecard{
    margin-top: 190px;
}

.homeContent__nets {
    width: 75%;
    padding: 15px;
   
}
.homecontent__extreme_right{
    

}
.homeContent__recent {
    padding: 15px;
    
}

.homecontent__Advertisement{
    padding: 15px;

}
.recentCard{
    padding: 30px !important;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.recentCard__divider{
    height: 1px;
    background-color: #d21a22;
    width: 30%;
    margin: 15px 0 30px 0;
}

.recentCard__NewsleftIcon{
    font-size: 40px !important;
}

.recentCard__newsSingleItem{
    display: flex;
    margin: 20px 0;
}

.recentCard__newsTextWrapper{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
}

.recentCard__newsHeading{
    font-size: 13px;
    font-weight: 700;
    color: #333;
}

.recentCard__newsTime{
    font-size: 12px;
    font-weight: 500;
    color: #666;
}
.Advertisement{
    padding: 30px !important;    
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.Advertisement_divider{
    height: 1px;
    background-color: #d21a22;
    width: 30%;
    margin: 10px 0 10px 0;
}

.Advertisement_name{
    font-size: 24px;
}

.Advertisement_image{
    width: 100% !important  ;
    height: 100% !important ;
}

.Advertisement_username{
    font-weight: 500;
}

.Advertisement_body{
    margin-top: 20px;
}

.Advertisement_about{
    margin-bottom: 20px;
}


.Advertisement_introListItem{
    margin: 10px 0;
   display: flex ;
   justify-content: flex-start;
   align-items: center;
}

.Advertisement_introListItem span{
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
}

  .UserProfile_profile-card {
    width: 100%;
    text-align: center;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 8px;
    overflow: hidden;
  }

  .UserProfile_card-header {
    background: url("https://source.unsplash.com/500x500/?science,technology") no-repeat center;
    padding: 60px 40px;
  }

  .UserProfile_pic {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 50%;
  }

  .UserProfile_profileimage {
    position: absolute;
    background: linear-gradient(130deg, #D21A22, #192A57, #EFBC1A);
    padding: 2px;
    background-size: 200% 200%;
    -webkit-animation: animated-gradient 5s linear infinite;
            animation: animated-gradient 5s linear infinite;
    transform: translateY(-50%);
  }

  @-webkit-keyframes animated-gradient {
    25% {
      background-position: left bottom;
    }
    50% {
      background-position: right bottom;
    }
    75% {
      background-position: right top;
    }
    100% {
      background-position: left top;
    }
  }

  @keyframes animated-gradient {
    25% {
      background-position: left bottom;
    }
    50% {
      background-position: right bottom;
    }
    75% {
      background-position: right top;
    }
    100% {
      background-position: left top;
    }
  }

  .UserProfile_pic img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .UserProfile_fbicon {
    margin: 10px;
  }

  .UserProfile_twittericon {
    margin: 10px;
  }

  .UserProfile_githubicon {
    margin: 10px;
  }

  .UserProfile_linkedinicon {
    margin: 10px;
  }

  .UserProfile_name {
    color: black;
    font-size: 28px;
    font-weight: 600;
    margin-top: 80px;
  }

  .UserProfile_desc {
    font-size: 18px;
    color: black;
  }

  .UserProfile_sm {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .UserProfile_sm a {
    color: black;
    width: 56px;
    font-size: 22px;
    transition: .3s linear;
  }

  .UserProfile_sm a:hover {
    color: black;
  }

  .UserProfile_contact-btn {
    display: inline-block;
    padding: 12px 50px;
    color: black;
    border: 2px solid black;
    border-radius: 6px;
    margin-top: 16px;
    transition: .3s linear;
    text-decoration: none;
  }

  .UserProfile_contact-btn:hover {
    background: black;
    color: #f2f2f2;
  }

  /* .loginNetCard {
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 0px !important;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px !important;
  }

  .loginNetCard__tags {
    width: 95%;
  }

  .loginNetCard__tags span {
    padding: 1px 12px;
    border-radius: 12px;
    margin: 0 2px;
    background: rgb(18, 37, 86);
    color: white;
    text-transform: uppercase;
  }

  .loginNetCard__heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 5px 0px 14px 0;
  }

  .loginNetCard__subheading {
    padding-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  .loginNetCard__video {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
  }

  .loginNetCard__lcsIcons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .loginNetCard__lcsIcons span {
    margin-left: 5px;
  }

  .loginNetCard__csIcons {
    display: flex;
    justify-content: space-between;
  }

  .loginNetCard__likeIcon {
    display: flex;
    align-items: flex-start;
  }

  .loginNetCard__commentIcon {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
  }

  .loginNetCard__shareIcon {
    display: flex;
    align-items: flex-start;
  }

  .Netcard_header {
    display: flex;
  }

  .NetCard_Hamburger {
    display: flex !important;
    align-items: flex-end !important;
  }
   */
.HomeNet{
    display: flex;
    margin-bottom: 50px;

}
.HomeNet_Left{
    width: 35%;
}

.HomeNet_right{
    margin-left: 20px;
    width: 65%;
}
.JobNetCard {
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px !important;
    align-content: flex-start !important;


}

.JobNetCard_header{
  margin-top: 10px;
}


  .JobNetCard__tags {
    margin-top: 40px !important;
  }
  
  .JobNetCard__singleTag {
    margin-right: 5px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-color: #aaa !important;
    background-color: #eee !important;
    color: black !important;
  }

.JobNetCard_ProgressBar {

  width: 100%;
  margin-top: 20px;
}

.JobNetCard__ribbon {
  position: absolute;
  transform: translateY(15px);
  padding: 2px 15px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.0em;
  width: 120px;
  color: white;
  border-radius: 0 0.156em 0.156em 0;
  background: #D21A22;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

.JobNetCard__ribbon:before, .JobNetCard__ribbon:after {
  position: absolute;
  content: '';
  display: block;
}

.JobNetCard__ribbon:before {
  width: 0.469em;
  height: 100%;
  padding: 0 0 0.438em;
  top: 0;
  left: -0.469em;
  background: inherit;
  border-radius: 0.313em 0 0 0.313em;
}

.JobNetCard__ribbon:after {
  width: 0.313em;
  height: 0.313em;
  background: rgba(0, 0, 0, 0.35);
  bottom: -0.313em;
  left: -0.313em;
  border-radius: 0.313em 0 0 0.313em;
  box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .JobNetCard__ribbon {
      line-height: 1.143em;
      padding: 0.5em;
  }
  .JobNetCard__ribbon:before, .JobNetCard__ribbon:after {
      font-size: 0.714em;
  }
}

.JobNetTabs{
  margin-top: 20px;
  margin-bottom: 20px;
}
.JobNetCard_ProgressBar {

    width: 100%;
    margin-top: 20px;
  }
  
  .JobNetCard__ribbon {
    position: absolute;
    transform: translateY(15px);
    padding: 2px 15px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.0em;
    width: 120px;
    color: white;
    border-radius: 0 0.156em 0.156em 0;
    background: #D21A22;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
  }
  
  .JobNetCard__ribbon:before, .JobNetCard__ribbon:after {
    position: absolute;
    content: '';
    display: block;
  }
  
  .JobNetCard__ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
  }
  
  .JobNetCard__ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 600px) {
    .JobNetCard__ribbon {
        line-height: 1.143em;
        padding: 0.5em;
    }
    .JobNetCard__ribbon:before, .JobNetCard__ribbon:after {
        font-size: 0.714em;
    }
  }

.coverAndProfile__coverPhotoWrapper {
    width: 100%;
    max-height: 370px;
}

.coverAndProfile__coverPhoto {
    width: 100%;
    height: 370px;
}

.coverAndProfile__belowCoverPhoto {
    margin: 0 10%;
    display: flex;
    justify-content: space-between;
}

.coverAndProfile__blankProfilePhotoWrapper {
    width: 50px;
}

.coverAndProfile__profilePhotoWrapper {
    position: absolute;
    transform: translateY(calc(-60% - 10px));
    border: 10px solid #fff;
    box-shadow: 0 8px 6px -6px black;
}

.coverAndProfile__profilePhoto{
    width: 225px;
    height: 300px;
    padding: 0;
    max-width: 225px;
    max-height: 300px;
}


.coverAndProfile__profileMenu {
    display: flex;
    width: 300px;
    justify-content: space-between;
    align-items: center;
    color: #333;
}


.profileContent {
    display: flex;
    margin: 0 10%;
}

/*---------------- Divided in to three columns ------------*/
.profileContent__userDescription {
    width: 25%;
    padding: 15px;
}

.profileContent__nets {
    width: 50%;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.profileContent__recent {
    width: 25%;
    padding: 15px;
}
/************************ Three Columns End ******************************/

/*---------------------- First Column---------------  */
.profileContent__userDescriptionTopEmptySpace {
    height: 90px;
}
/************************ First Column End ***********************/

/* ------------------ Middle Column ----------------------- */
.profileContent__createNetBox{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.profileContent__createNetButton{
    width: 100%;
    height: 44px;
    padding: 0px 20px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 1px 10px 0px rgba(85, 85, 85, 0.2);
    color: grey;
}


.profileContent__createNetButton_videoIcon{
    font-size: 30px !important;
}

/* ****************** Middle Column End ***************** */
.userDescriptionCard{
    padding: 30px !important;    
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.userDescriptionCard__divider{
    height: 1px;
    background-color: #d21a22;
    width: 30%;
    margin: 10px 0 10px 0;
}

.userDescriptionCard__name{
    font-size: 24px;
}

.userDescriptionCard__username{
    font-weight: 500;
}

.userDescriptionCard__body{
    margin-top: 20px;
}

.userDescriptionCard__about{
    margin-bottom: 20px;
}


.userDescriptionCard__introListItem{
    margin: 10px 0;
   display: flex ;
   justify-content: flex-start;
   align-items: center;
}

.userDescriptionCard__introListItem span{
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
}
.NetCard {
    /* box-shadow: 0px 0px 8px 1px #D21A22 !important; */
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    border-radius: 0px !important;
    width: 100%;
    margin-bottom: 20px !important;
    transition: all 5s !important;
}

.Netcard_header {
    display: flex;
    padding: 0 8px 5px 22px;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.NetCard_Hamburger {
    display: flex !important;
    align-items: flex-end !important;
    z-index: 10 !important;
    outline: none !important;
    border: none !important;
}

.NetCard_hamburgerMenu {
    z-index: inherit !important;
    outline: inherit !important;
    border: inherit !important;
}

.NetCard__tags {
    margin-top: 53px !important;
}


.NetCard__singleTag {
    margin-right: 5px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-color: #aaa !important;
    background-color: #eee !important;
    color: black !important;
}

.NetCard__post{
    padding: 0 25px 25px 25px;
}

.NetCard__accordion {
    padding: 0px !important;
    box-shadow: none !important;
    z-index: 0 !important;
    margin: 0 !important;
}

.NetCard__accordionSummary{
    margin: 0 !important;
    padding: 0 !important;
}

.NetCard__accordionHeading{
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

.NetCard__accordionSubheading{
    font-family: 'Montserrat', sans-serif;
}


.NetCard__headingAndSubheading {
    z-index: 0 !important;
}


.NetCard__heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 5px 0px 10px 0 !important;
}

.NetCard__subheading {
    padding-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
}

.NetCard__video {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
}

.NetCard__image_wrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}

.NetCard__image {
    width: 100%;
    height: 300px;
    border-radius: 10px;    
}

.NetCard__lcsIcons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.NetCard__lcsIcons span {
    margin-left: 5px;
}

.NetCard__csIcons {
    display: flex;
    justify-content: space-between;
}

.NetCard__likeIcon {
    display: flex;
    align-items: flex-start;
}

.NetCard__commentIcon {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
}

.NetCard__shareIcon {
    display: flex;
    align-items: flex-start;
}

.NetCard__ribbon {
    position: absolute;
    transform: translateY(15px);
    padding: 3px 15px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.0em;
    width: 120px;
    color: white;
    border-radius: 0 0.156em 0.156em 0;
    background: #D21A22;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
}

.NetCard__ribbon:before, .NetCard__ribbon:after {
    position: absolute;
    content: '';
    display: block;
}

.NetCard__ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
}

.NetCard__ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}


.coverAndProfile__coverPhotoWrapper {
    width: 100%;
    max-height: 370px;
}

.coverAndProfile__coverPhoto {
    width: 100%;
    max-height: 370px;
}

.coverAndProfile__belowCoverPhoto {
    margin: 0 10%;
    display: flex;
    justify-content: space-between;
}

.coverAndProfile__blankProfilePhotoWrapper {
    width: 50px;
}


.coverAndProfile__blankProfilePhotoWrapper2 {
    width: 350px;
}



.coverAndProfile__profilePhotoWrapper {
    max-width: 245px;
    max-height: 320px;
    position: absolute;
    transform: translateY(calc(-60% - 10px));
    border: 10px solid #fff;
    box-shadow: 0 8px 6px -6px black;
}

.coverAndProfile__profilePhoto{
    padding: 0;
    max-width: 225px;
    max-height: 300px;
}


.coverAndProfile__profileMenu {
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;
    color: #333;

}

.coverAndProfile__socialmenu {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    color: #333;
    margin-top: 20px;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;


}
.coverAndProfile__About{
    align-content: flex-start;
    margin-right: 125px;
}

.coverAndProfile__SocialIcon{
    display: flex;
    align-items: center;
}

.active {
    color: #D21A22;
}


.MyProfileContent {
    display: flex;
    margin: 0 10%;
}

/*---------------- Divided in to three columns ------------*/
.MyProfileContent__userDescription {
    width: 30%;
    padding: 15px;
}

.MyProfileContent__userDescriptionTopEmptySpace {
    height: 30px;
}
.MyProfileDescription{
    padding: 30px !important;    
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.MyProfileDescription__divider{
    height: 1px;
    background-color: rgb(165, 164, 164);
    width: 100%;
    margin: 10px 0 10px 0;
}


/* about me button starts */
.MyProfileDescription__aboutme{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}
.MyProfileDescription__aboutme:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/* about me button starts */

/* working button starts */
.MyProfileDescription__working{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__working:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/* working button ends */

/* education button starts */


.MyProfileDescription__education{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}
.MyProfileDescription__education:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/* education button ends */

/*work button starts */

.MyProfileDescription__work{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__work:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/*work button ends */

/*friends button starts */


.MyProfileDescription__friends{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__friends:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}

/*friends button ends */

/*Contact button starts */

.MyProfileDescription__contact{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__contact:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}

/*Contact button ends */


.MyProfileDescription__body{
    margin-top: 20px;
}

.MyProfileDescription__about{
    margin-bottom: 20px;
}


.MyProfileDescription__introListItem{
    margin: 10px 0;
   display: flex ;
   justify-content: flex-start;
   align-items: center;
}

.MyProfileDescription__introListItem span{
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
}
.createNetDialog__titleBox {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
}

.createNetDialog__title {
    display: flex !important;
    justify-content: flex-start;
    align-items: center !important;
    font-size: 26px;
    font-weight: 600;
}

.createNetDialog__magnetIcon {
    margin-left: 10px;
}

.createNetDialog__textFields {
    width: 100% !important;
    margin-bottom: 10px !important;
}

.createNetDialog__mediaTypeForm{
    margin-top: 10px !important;
}

.createNetDialog__descriptionField {
    outline: none !important;
    border: none !important;
}

.createNetDialog__tagsContainer {
    margin-top: 10px !important;
}

.createNetDialog__addTagContainer {
    margin-top: 10px !important;
}

.createNetDialog__addTagInput {
    width: 100%;
}

.createNetDialog__addTagButton {
    margin-left: 10px !important;
    width: 30%;
}

.createNetDialog__showTagsContainer {
    padding: 10px !important;
    display: flex;
}

.createNetDialog__tagChip {
    border-radius: 12px !important;
    margin: 0 2px !important;
    background: rgb(18, 37, 86) !important;
    color: white !important;
    text-transform: uppercase;
}

.createNetDialog__previewButton{
    background-color: rgb(240, 240, 240) !important;
    margin-right: 10px !important;
}

.createNetDialog__publishButton{
    margin-right: 16px !important;
}
