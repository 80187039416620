.loginNetCard{
    /* box-shadow: 0px 0px 8px 1px #D21A22 !important; */
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
    /* box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2) !important; */
    border-radius: 4px !important; 
    width: 100%;
    padding: 0 !important;
    margin-bottom: 10px !important;
}


.loginNetCard__profile_wrapper{
    display: flex;
    justify-content: flex-start; 
    padding: 20px;
}

.loginNetCard__profilePhoto{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 8px 10px -5px #000;
}

.loginNetCard__nameAndDesignation{
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
   
}

.loginNetCard__name{
    font-size: 30px;
    font-weight: 500;
}

.loginNetCard__designation{
    font-size: 20px;
    font-weight: 400;
}

.loginNetCard__netWorth{
    font-size: 12px;
}

.loginNetCard__divider{
    height: 1px;
    width: 100%;
    /* margin: 15px 0; */
    background-color: #ddd;
}


.loginNetCard__tags {
    margin-top: 55px !important;
}


.loginNetCard__singleTag {
    margin-right: 5px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    border-color: #aaa !important;
    background-color: #eee !important;
    color: black !important;
}

.loginNetCard__post{
    padding: 0 25px 25px 25px;
}

.loginNetCard__accordion {
    padding: 0px !important;
    box-shadow: none !important;
    z-index: 0 !important;
    margin: 0 !important;
}

.loginNetCard__accordionSummary{
    margin: 0 !important;
    padding: 0 !important;
}

.loginNetCard__accordionHeading{
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
}

.loginNetCard__accordionSubheading{
    font-family: 'Montserrat', sans-serif;
}


.loginNetCard__headingAndSubheading {
    z-index: 0 !important;
}


.loginNetCard__heading {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 10px 0px !important;
}

.loginNetCard__subheading {
    padding-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
}

.loginNetCard__video {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    border: none;
    outline: none;
}

.loginNetCard__image_wrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
}

.loginNetCard__image {
    width: 100%;
    height: 300px;
    border-radius: 10px;    
}

.loginNetCard__lcsIcons {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.loginNetCard__lcsIcons span {
    margin-left: 5px;
}

.loginNetCard__csIcons {
    display: flex;
    justify-content: space-between;
}

.loginNetCard__likeIcon {
    display: flex;
    align-items: flex-start;
}

.loginNetCard__commentIcon {
    display: flex;
    align-items: flex-start;
    margin-right: 15px;
}

.loginNetCard__shareIcon {
    display: flex;
    align-items: flex-start;
}

.loginNetCard__ribbon {
    position: relative;
    transform: translateY(180px);
    padding: 3px 15px;
    font-size: 19px;
    font-weight: 400;
    line-height: 1.0em;
    width: 120px;
    color: #fff;
    border-radius: 0 0.156em 0.156em 0;
    background: #d21a22;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
}

.loginNetCard__ribbon:before, .loginNetCard__ribbon:after {
    position: absolute;
    content: '';
    display: block;
}

.loginNetCard__ribbon:before {
    width: 0.469em;
    height: 100%;
    padding: 0 0 0.438em;
    top: 0;
    left: -0.469em;
    background: inherit;
    border-radius: 0.313em 0 0 0.313em;
}

.loginNetCard__ribbon:after {
    width: 0.313em;
    height: 0.313em;
    background: rgba(0, 0, 0, 0.35);
    bottom: -0.313em;
    left: -0.313em;
    border-radius: 0.313em 0 0 0.313em;
    box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
}
