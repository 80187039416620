.MyProfileDescription{
    padding: 30px !important;    
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.MyProfileDescription__divider{
    height: 1px;
    background-color: rgb(165, 164, 164);
    width: 100%;
    margin: 10px 0 10px 0;
}


/* about me button starts */
.MyProfileDescription__aboutme{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}
.MyProfileDescription__aboutme:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/* about me button starts */

/* working button starts */
.MyProfileDescription__working{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__working:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/* working button ends */

/* education button starts */


.MyProfileDescription__education{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}
.MyProfileDescription__education:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/* education button ends */

/*work button starts */

.MyProfileDescription__work{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__work:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}
/*work button ends */

/*friends button starts */


.MyProfileDescription__friends{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__friends:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}

/*friends button ends */

/*Contact button starts */

.MyProfileDescription__contact{
    font-weight: 500;
    border: none;
    background-color: white;
    font-size: 16px;
    outline: none;
}

.MyProfileDescription__contact:focus{
    font-weight: 500;
    border: none;
    color: #D21A22;
    font-size: 16px;
    outline: none;
}

/*Contact button ends */


.MyProfileDescription__body{
    margin-top: 20px;
}

.MyProfileDescription__about{
    margin-bottom: 20px;
}


.MyProfileDescription__introListItem{
    margin: 10px 0;
   display: flex ;
   justify-content: flex-start;
   align-items: center;
}

.MyProfileDescription__introListItem span{
    font-size: 15px;
    margin-left: 8px;
    font-weight: 600;
}