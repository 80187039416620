.HomeNet{
    display: flex;
    margin-bottom: 50px;

}
.HomeNet_Left{
    width: 35%;
}

.HomeNet_right{
    margin-left: 20px;
    width: 65%;
}