.profileContent {
    display: flex;
    margin: 0 10%;
}

/*---------------- Divided in to three columns ------------*/
.profileContent__userDescription {
    width: 25%;
    padding: 15px;
}

.profileContent__nets {
    width: 50%;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.profileContent__recent {
    width: 25%;
    padding: 15px;
}
/************************ Three Columns End ******************************/

/*---------------------- First Column---------------  */
.profileContent__userDescriptionTopEmptySpace {
    height: 90px;
}
/************************ First Column End ***********************/

/* ------------------ Middle Column ----------------------- */
.profileContent__createNetBox{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.profileContent__createNetButton{
    width: 100%;
    height: 44px;
    padding: 0px 20px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 1px 10px 0px rgba(85, 85, 85, 0.2);
    color: grey;
}


.profileContent__createNetButton_videoIcon{
    font-size: 30px !important;
}

/* ****************** Middle Column End ***************** */