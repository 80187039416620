.recentCard{
    padding: 30px !important;
    box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2) !important;
}

.recentCard__divider{
    height: 1px;
    background-color: #d21a22;
    width: 30%;
    margin: 15px 0 30px 0;
}

.recentCard__NewsleftIcon{
    font-size: 40px !important;
}

.recentCard__newsSingleItem{
    display: flex;
    margin: 20px 0;
}

.recentCard__newsTextWrapper{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
}

.recentCard__newsHeading{
    font-size: 13px;
    font-weight: 700;
    color: #333;
}

.recentCard__newsTime{
    font-size: 12px;
    font-weight: 500;
    color: #666;
}